//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
// $primary:       									#3699FF; // Bootstrap variable
// $primary-hover:    									#187DE4; // Custom variable
// $primary-light:    									#E1F0FF; // Custom variable
// $primary-inverse:  									#FFFFFF; // Custom variable

// Danger colors
// $primary:        									#F64E60; 
// $primary-hover:     								#EE2D41;
// $primary-light:     								#FFE2E5;
// $primary-inverse:   								#FFFFFF;

// gray
// $primary-inverse: 									#B5B5C3;
// $primary-light: 									#7E8299;
// $primary: 											#5E6278;
// $primary-hover: 									#3F4254;

// light gray
$primary-inverse: 									#F3F6F9; //#F3F6F9;// #D1D3E0;
$primary-light: 									#E4E6EF; // #B5B5C3;
$primary: 											#02081B;
$primary-hover:  									#00082e;
$text-secondary: 											#DC3940;
$gray-800: 											#02081B;
$gray-700: 											#F7E158;
$branding-color:                                    #02081B;
$branding-icon-color:                               #DC3940;
$brand-icon-color-hover:                             #DC3940;
$header-mobile-icon-color:                          #fff;
$header-mobile-icon-color-hover:                    #DC3940;
$menu-text-color:                                   #fff;
$menu-icon-color:                                   #DC3940;
$menu-icon-active-color:                            #F7E158;
$menu-self-active-color:                            #DC3940;
// $dark-75: #5E6278;