@import './variables.scss';
@import './metronic-theme.scss';

body {
    /*Cutom Only*/

    .modal-no-submit {
      button[type="submit"].btn {
        display: none;
      }
    }

    .profile-photo-hover {
      max-width: unset !important;

      .popover-body > img {
        width: 300px;
        height: 300px;
      }
    }

    .profile-photo {
      > img {
        width: 30px;
        height: 30px;
      }
    }

    .spread-sheet {

      &:has(.collapse.show) {
        .card-header {
          background-color: #f3f6f9e1 !important;
          .text-muted {
            color: #5f5f62 !important;
          }
        }
      }

      &:has(.card-highlight:hover):has(.collapse.show):hover,
      &:has(.collapse.show):has(:focus-within) {
        .card-highlight:not(:hover):not(:has(:focus-within)) {
          opacity: 0.3;
        }
      }
    }
    .card-highlight {
      border-radius: 0;
      transition: opacity 150ms ease-in;
      .card-header {
        transition: background-color 150ms ease-in;
      }
      &:has(.collapse.show):hover {
        outline: 1px solid var(--primary);
      }
    }

    .bilot-bilot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 5px;
    }

    .spread-sheet-footer-center {
      .spread-sheet-footer {
        justify-content: center;
      }
    }

    .drag-over {
      opacity: 0.3;

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        z-index: 1000;
      }
    }

    .modify-input-group-range {
      .input-group {
        flex-wrap: nowrap;

        &-text {
          border-radius: 0.42rem !important;
        }

        &-append {
          margin-left: 0.42rem !important;
        }
      }
    }

    .kt-splash-screen {
      background-color: #fff;
    }

    .btn-rounded.btn-clean {
      border-radius: 50px;
      background-color: #F64E60 !important;
      transition: all 500ms ease-in-out !important;

      & i {
        color: #fff;
      }

      &:hover {
        background-color: #F3F6F9 !important;
        border-radius: 3px;

        i {
          color: #F64E60 !important;
        }
      }
    }

    .react-datepicker__week-number {
      font-weight: 600;
      color: #000 !important;
    }

    .form-week-number {
      .react-datepicker-popper {
        z-index: 100;
  
        .react-datepicker__week:has(.react-datepicker__week-number):has(.react-datepicker__day:not(.react-datepicker__day--disabled):hover, .react-datepicker__day--selected) {
          .react-datepicker__week-number {
            border-radius: 15px;
            background-color: $primary !important;
            color: #fff !important;
          }
          .react-datepicker__day {
            background-color: $primary !important;
            color: #fff !important;
            margin: 0.166rem 0;
            padding: 0 0.166rem;
            box-sizing: content-box;
          }
        }
      }
    }


    .select-label-between {
      .select-text {
        display: block;
        width: 100%;
      }
    }

    .check-list-remove-pad-first:first-child {
      padding-left: 18px;
    }

    .fs-11 {
      font-size: 11px !important;
    }

    /*Cutom Only*/
}