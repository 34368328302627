.unsubscribe-container {
    max-width: 500px;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;

    .baby-img {
        position: absolute;
        width: 100%;
        object-fit: cover;
        object-position: 70%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        transition: opacity 150ms linear;
        border-radius: 5px;
    }

    h1 {
        font-size: 2.5rem;
        color: #ff6b6b;
        margin-bottom: 20px;
    }
    p {
        font-size: 1rem;
        color: #6c757d;
        line-height: 1.5;
    }
    .btn-unsubscribe {
        margin-top: 1.5rem;
        padding: 0.6rem 2rem;
        font-size: 1rem;
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: 50px;
        position: relative;
    }
    .btn-unsubscribe:hover {
        background-color: #0056b3;
    }
    .footer-note {
        font-size: 0.9rem;
        color: #6c757d;
        margin-top: 1rem;
    }
}
  
.unsubscribe-main {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 0%;
    background-position: 0%;
    background-repeat: no-repeat;

    &.success {
        background-size: cover;
        background-position: 60%;
        animation: wobbleCryingBaby 1.5s ease-in-out ;
        animation-fill-mode: forwards;

        .unsubscribe-container {
            display: none;
        }
    }
}

@keyframes wobbleCryingBaby {
    0% {
        transform: rotate(0deg) scale(1);
    }
    2.5% {
        transform: rotate(3deg) scale(1.05);
    }
    5% {
        transform: rotate(-3deg) scale(1.1);
    }
    7.5% {
        transform: rotate(4deg) scale(1.05);
    }
    10% {
        transform: rotate(-4deg) scale(1.1);
    }
    12.5% {
        transform: rotate(2deg) scale(1.05);
    }
    15% {
        transform: rotate(-2deg) scale(1.1);
    }
    17.5% {
        transform: rotate(3deg) scale(1.05);
    }
    20% {
        transform: rotate(-3deg) scale(1.1);
    }
    22.5% {
        transform: rotate(1deg) scale(1.05);
    }
    25% {
        transform: rotate(-1deg) scale(1.1);
    }
    27.5% {
        transform: rotate(2deg) scale(1.05);
    }
    30% {
        transform: rotate(-2deg) scale(1.1);
    }
    32.5% {
        transform: rotate(1.5deg) scale(1.05);
    }
    35% {
        transform: rotate(-1.5deg) scale(1.1);
    }
    37.5% {
        transform: rotate(0.5deg) scale(1.05);
    }
    40% {
        transform: rotate(-0.5deg) scale(1.1);
    }
    42.5% {
        transform: rotate(1deg) scale(1.05);
    }
    45% {
        transform: rotate(-1deg) scale(1.1);
    }
    47.5% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(2deg) scale(1.05);
    }
    52.5% {
        transform: rotate(-2deg) scale(1.1);
    }
    55% {
        transform: rotate(1.5deg) scale(1.05);
    }
    57.5% {
        transform: rotate(-1.5deg) scale(1.1);
    }
    60% {
        transform: rotate(0.5deg) scale(1.05);
    }
    62.5% {
        transform: rotate(-0.5deg) scale(1.1);
    }
    65% {
        transform: rotate(1deg) scale(1.05);
    }
    67.5% {
        transform: rotate(-1deg) scale(1.1);
    }
    70% {
        transform: rotate(0deg) scale(1);
    }
    72.5% {
        transform: rotate(3deg) scale(1.05);
    }
    75% {
        transform: rotate(-3deg) scale(1.1);
    }
    77.5% {
        transform: rotate(4deg) scale(1.05);
    }
    80% {
        transform: rotate(-4deg) scale(1.1);
    }
    82.5% {
        transform: rotate(2deg) scale(1.05);
    }
    85% {
        transform: rotate(-2deg) scale(1.1);
    }
    87.5% {
        transform: rotate(3deg) scale(1.05);
    }
    90% {
        transform: rotate(-3deg) scale(1.1);
    }
    92.5% {
        transform: rotate(1deg) scale(1.05);
    }
    95% {
        transform: rotate(-1deg) scale(1.1);
    }
    97.5% {
        transform: rotate(0deg) scale(1);
    }
    100% {
        transform: rotate(0deg) scale(1);
    }
}